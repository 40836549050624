// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
    production: true,
    version: env.npm_package_version,
    serverUrl: 'https://mcms-api.stage.scaliolabs.com/', //'https://mcms-api.scal.io/',
    baseUrl: 'https://virtueanimation.com', //baseUrl: 'https://atomik.scaliolabs.com', //appDomain baseUrl: 'http://localhost:4000'
    awsS3: {
        AWSAccessKeyId: 'AKIAVSK2676HTM6F5YHK',
        policy: 'ewogICJleHBpcmF0aW9uIjogIjIwMjktMDEtMDFUMDA6MDA6MDBaIiwKICAiY29uZGl0aW9ucyI6IFsKICAgIHsiYnVja2V0IjogImNkbi1zY2FsaW9tY21zLXByb2QifSwKICAgIFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICIiXSwKICAgIHsiYWNsIjogInB1YmxpYy1yZWFkIn0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRDb250ZW50LVR5cGUiLCAiIl0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRmaWxlbmFtZSIsICIiXSwKICAgIFsiY29udGVudC1sZW5ndGgtcmFuZ2UiLCAwLCA1MjQyODgwMDBdCiAgXQp9',
        signature: 'Li4xEBRReuq45X/C1SI1yZKfQoU=',
        baseUrl: 'https://cdn-scaliomcms-prod.s3.amazonaws.com',
    },
};
