import { CardModel } from './../../@shared/atomic/molecules/m-cards-item/m-cards-item.model';
import { Component, OnInit } from '@angular/core';
import { TeamMember } from '@app/@shared/cms/models/team-member.model';
import { PageBase } from '../../@shared/base/page.base';
import { MediaService } from '../../@shared/utils/media/media.service';
import { MediaPlayerModel } from '../../@shared/utils/media/media-player.model';
import { VideoOptions } from '../../@shared/atomic/organisms/o-video-section/o-video.model';
import { MessageServiceModel } from '../../@shared/utils/message/message.model';
import { AnimationsService } from '@src/app/@shared/utils/common/animations.service';
import { PartnerCardModel } from '../../components/atomic/organisms/o-partner-cards/o-partner-cards.model';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends PageBase implements OnInit {
    quote: string | undefined;
    isLoading = false;
    isPlaying = true;
    headerVideoBlock!: VideoOptions;
    joinSection!: any[];
    media!: MediaPlayerModel;
    lidershipMembers!: TeamMember[];
    cardsGlobalTech!: any[];
    innovativeProducts!: any[];
    cardsWithLinkCarousel: CardModel[] = [];
    content!: string;
    contentDefault: string = `We're a digital product agency <span class='brand-color'>advancing technology</span> for <br>`;
    partners: Array<PartnerCardModel> = [];

    phrasesToDecode = [
        "the world's leading companies.",
        'machine learning & augmented reality.',
        'surgical robots & AI-based systems.',
        'marketing & advertising organizations.',
        'healthcare & biotech.',
        'e-commerce & bidding platforms.',
        'smart devices & connected vehicles.',
        'think tanks, nonprofits, and NGOs.',
        'the media & entertainment industry.',
        'researchers and scientists.',
        'real-time collaboration platforms.',
    ];

    constructor(public mediaService: MediaService, public animationsService: AnimationsService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        this.partners = [
            {
                title: 'Alex Orrelle',
                content: `<p>A veteran leader of both Hollywood and international animation, writer-director Alex Orrelle was recently the Animation Head of Story on Warner Bros Pictures’ Space Jam; A New Legacy(2021), starring and produced by LeBron James. Before that, Alex was the creative consultant for the Oscar-nominated and multiple award-winning Klaus (2019), made at SPA Studios for Netflix.</p>
            <p>Alex started his career at Pixar as an animator for Finding Nemo (2003) and The Incredibles (2004). He then set up a CG animation crew for Warner Bros Animation’s The Looney Tunes Show and was animation director on Yogi Bear (2010) for Warner Bros Pictures. Alex studied at the Academy of Art University in San Francisco, where he created his student film Freeware, which won the online audience choice award at the Sundance Film festival in 2001.</p>`,
                imgSrc: '/assets/img/alex_orrelle.webp',
                imgSrcMobile: '/assets/img/alex_orrelle_mobile.webp',
            },
            {
                title: 'Marc Levine',
                content: `<p>Film producer, Marc Levine, produces sports-themed content including live-action feature films and documentaries. Marc is currently serving as Associate Producer and Story Consultant on the upcoming $50M feature film about an iconic daredevil, Evel Knievel.</p>
            <p>Previously, Marc helped produce the Emmy Award-winning Netflix feature sports documentary, Unchained, The Untold Story of Freestyle Motocross.</p>
            <p>Marc spent his early years in key roles with legendary animation producers, “Spike & Mike” which included identifying up-and-coming new talent for the cutting-edge film festivals including:</p>
            <p>Mike Judge creator of Beavis and Butthead
            <br/>Matt Stone & Trey Parker creators of South Park
            <br/>Academy Award-nominated animator Bill Plympton
            <br/>John Lasseter former Pixar head and Toy Story director
            <br/>Peter Docter director of Up, Monsters Ince, Inside Out, and Soul</p>`,
                imgSrc: '/assets/img/marc_levine.webp',
                imgSrcMobile: '/assets/img/marc_levine_mobile.webp',
            },
            {
                title: 'Colin',
                content: `<p>Colin is a recent graduate from USC with a double major in Data Science and Cognitive Science and a Blockchain specialization. He has experience in project management and IT development at NBC and Red Bull, while leading multiple entrepreneurship organizations on campus. He began his journey in the Web3 space in late 2020 by co-founding Tweak Labs, an art-focused mental health brand.<br/><br/> Colin has a passion for helping creatives execute on innovative ideas within the NFT space and has successfully launched multiple projects, while working with some of the most reputable artists in the space.</p>`,
                imgSrc: '/assets/img/colin.jpg',
                imgSrcMobile: '/assets/img/colin_mobile.jpg',
            },
            {
                title: '',
                imgTitle: '/assets/img/scalio_logo.webp',
                link: 'https://scal.io/',
                content: `<p>
            <a class="text-white" target="_blank" href="https://scal.io/">Scalio</a> is a leading global product development agency that partners with passionate leaders, visionary entrepreneurs and industry titans to understand and unlock opportunity in an evolving Web3 world.</p>
            <p>Over 11 years, <a class="text-white" target="_blank" href="https://scal.io/">Scalio</a> has designed, architected and scaled hundreds of mobile and web applications including numerous top-ten mobile applications, high-trafficked web systems, and big-data architectures for venture-backed startups and Fortune 100 companies. Scalio's leadership have brought to market products ranging from games to finTech and medTech products, delighting well over 100 million users within a product catalog yielding over $1 billion in revenue.</p>
            <p><a class="text-white" target="_blank" href="https://scal.io/">Scalio</a> is Virtue Animation's Web3 technology partner.</p>`,
                imgSrc: '/assets/img/scalio_card.jpg',
                imgSrcMobile: '/assets/img/scalio_card_mobile.webp',
            },
        ];

        this.content = this.contentDefault + this.phrasesToDecode[0];

        const messageSubs = this.messageService.getMessage().subscribe((msj: MessageServiceModel) => {
            if (msj.type === 'textScrambler') {
                this.content = this.contentDefault + msj.payload;
            }
        });
        this.subscriptions.push(messageSubs);

        const scramblerSub = this.animationsService.textScrambler(this.content, 0, this.phrasesToDecode);
        if (scramblerSub) this.subscriptions.push(scramblerSub);

        this.headerVideoBlock = {
            fluid: true,
            aspectRatio: '16:9',
            autoplay: true,
            loop: true,
            controls: false,
            muted: true,
            preload: 'metadata',
            poster: 'assets/videos/poster-video.jpg',
            sources: [
                {
                    src: 'https://scalio-assets.scaliolabs.com/assets/video/scalio-home-hero3-d5d2718c42.mp4',
                    type: 'video/mp4',
                },
            ],
        } as VideoOptions;
        this.joinSection = [
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 2',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 3',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 2',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 3',
            },
        ];
        (this.cardsGlobalTech = [
            {
                parallaxContent: '600+',
                title: '600+ PROJECTS',
                subTitle: 'In 30+ Industries',
                content:
                    "Over 10 years, we've completed 600+ projects across 30+ industries. 9 of our clients have been acquired. Intuitive solutions made for the most complex challenges.",
            },
            {
                parallaxContent: '100+',
                title: '100+ MILLON',
                subTitle: 'Delighted Users',
                content:
                    'Our experts have crafted products that have engaged over 100 million users across dozens of verticals. From finanical tech, to healthcare, to gaming - we know how to deliver world-class experiences.',
            },
            {
                parallaxContent: '$1 Bil',
                title: '$1 BILLION+',
                subTitle: 'In Revenue',
                content:
                    'With hundreds of products shipped, our experts have delivered over $1 billion in revenue on products over the past 10 years.',
            },
            {
                parallaxContent: '75+',
                title: '75+ SPECIALISTS',
                subTitle: 'Globally',
                content:
                    'A global workforce celebrated in diversity of skill & background. 75 unique technical specialties, 43 countries, and one very special mission.',
            },
        ]),
            (this.innovativeProducts = [
                {
                    icon: 'lni lni-code-alt',
                    title: 'Mobile Engineering',
                },
                {
                    icon: 'lni lni-pencil-alt',
                    title: 'Design & User Experience',
                },
                {
                    icon: 'lni lni-rocket',
                    title: 'Strategy & Growth',
                },
                {
                    icon: 'lni lni-code',
                    title: 'Web Engineering',
                },
                {
                    icon: 'lni lni-cloud',
                    title: 'DevOps & Hosting',
                },
                {
                    icon: 'lni lni-cog',
                    title: 'Support & Maintenance',
                },
            ]),
            (this.cardsWithLinkCarousel = [
                {
                    desc: 'I have nothing but great things to say about working with Scalio. They are one of the good ones...',
                    iconSet: false,
                    iconName: '/assets/icons/icon-person-doc.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/',
                },
                {
                    desc: 'I have nothing but great things to say about working with Scalio. They are one of the good ones...',
                    iconSet: false,
                    iconName: '/assets/icons/icon-person-doc.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/',
                },
                {
                    desc: 'I have nothing but great things to say about working with Scalio. They are one of the good ones...',
                    iconSet: false,
                    iconName: '/assets/icons/icon-person-doc.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/',
                },
            ] as CardModel[]);
        this.loadingStateService?.stop();
    }
}
