<footer
    class="o-footer position-relative"
    [ngClass]="[containerClass ? containerClass : '']">
    <div class="container-fluid py-7">
        <ng-content select="[header]"></ng-content>
        <a-title
            [contrast]="true"
            [title3]="title | translate"></a-title>
        <div [ngClass]="['d-grid', colClassCss]">
            <ng-content select="[containerLeftSide]"></ng-content>
            <div *ngFor="let footerItem of footerItems">
                <div>
                    <p
                        *ngIf="footerItem.category"
                        [innerHtml]="footerItem.category"
                        class="text-white category-footer"></p>
                    <m-footer-item
                        [containerClass]="footerItem.containerClass"
                        [hideMobile]="footerItem.hideMobile"
                        [hasBorder]="footerItem.hasBorder"
                        [footerLinks]="footerItem.items"></m-footer-item>
                </div>
            </div>
            <ng-content select="[containerRightSide]"></ng-content>
        </div>
    </div>
    <div class="copyright">
        <div class="container-fluid content py-5">
            <ng-content select="[content]"></ng-content>
        </div>
    </div>
</footer>
