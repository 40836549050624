<div class="cards-section">
    <div class="container-xxl py-7">
        <div
            class="row card-row-item"
            *ngFor="let item of partners"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once="true">
            <div class="col-12">
                <div class="card-item">
                    <div
                        *ngIf="item.imgTitle"
                        class="my-4">
                        <a
                            target="_blank"
                            [href]="item.link">
                            <a-image
                                class="card-image-title"
                                alt="virtue animation"
                                [imageConfig]="item.imgTitle"
                                data-aos="fade-up"
                                data-aos-delay="200"
                                data-aos-once="true"></a-image>
                        </a>
                    </div>
                    <h3
                        *ngIf="item.title"
                        class="card-title ps-0"
                        data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-once="true"
                        [innerHtml]="item.title"></h3>
                    <div class="row gx-lg-6 flex-column-reverse flex-lg-row">
                        <p
                            class="col-lg-9 ps-0"
                            data-aos="fade-up"
                            data-aos-delay="200"
                            data-aos-once="true"
                            [innerHtml]="item.content"></p>
                        <div
                            class="col-3 card-image p-0 mt-3 mb-5"
                            data-aos="fade-up"
                            data-aos-delay="200"
                            data-aos-once="true">
                            <a-image
                                *ngIf="!item.link"
                                alt="virtue animation"
                                [bgImageConfig]="item.imgSrc"></a-image>
                            <a
                                *ngIf="item.link"
                                target="_blank"
                                [href]="item.link">
                                <a-image
                                    alt="virtue animation"
                                    [bgImageConfig]="item.imgSrc"></a-image>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <a-link
                [isExternal]="true"
                url="mailto:colin@virtueanimation.com"
                class="card-btn"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-once="true"
                componentClass="btn btn-primary">
                <span>Get in Touch</span>
            </a-link>
        </div>
    </div>
</div>
