<div [style.visibility]="showPage ? 'visible' : 'hidden'">
    <o-navbar
        [navItems]="navbarMenu"
        [hideInRoutes]="hideInRoutes"></o-navbar>
    <router-outlet></router-outlet>
    <o-footer>
        <ng-container header>
            <div class="d-flex flex-column align-items-center">
                <a-icon
                    class="mb-5"
                    source="/assets/icons/logo.svg"></a-icon>
                <div class="d-flex flex-column align-items-center">
                    <p class="mb-3">Web3 technology powered by</p>
                    <a
                        target="_blank"
                        href="https://scal.io">
                        <a-icon source="/assets/icons/scalio-logo.svg"></a-icon>
                    </a>
                </div>
            </div>
        </ng-container>
        <ng-container content>
            <p class="text-white text-center">Virtue Animation – All right reserved {{ date }}</p>
        </ng-container>
    </o-footer>
</div>
