<div
    class="a-loader"
    [ngClass]="[containerClass ? containerClass : '']">
    <div
        *ngIf="logo"
        class="icon">
        <svg-icon src="/assets/icons/logo.svg"></svg-icon>
    </div>
    <ng-lottie
        width="10rem"
        *ngIf="logoLoader"
        [options]="loader"
        (animationCreated)="animationCreated($event)"></ng-lottie>
    <div
        *ngIf="isPaginatorLoader"
        class="paginator">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div
        *ngIf="background"
        class="background-loader"></div>
</div>
