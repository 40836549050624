<div class="home-page">
    <div class="header-section">
        <o-bg-image-section
            bgImage="/assets/img/header-bg-home.jpg"
            [overlay]="true"
            [parallaxValue]="0"
            [parallaxSpeed]="0.1">
            <ng-container content>
                <div class="container-xxl">
                    <div class="col-xxl-10 col-xl-9 p-0">
                        <h1
                            data-aos="fade-up"
                            data-aos-delay="200"
                            data-aos-once="true"
                            class="lead">
                            The future of animation is Film3
                        </h1>
                    </div>
                    <div class="col-lg-7 p-0">
                        <p
                            class="mb-3"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-once="true">
                            Virtue Animation is a WEB3 studio that produces innovative, high-quality IP for the
                            metaverse.
                        </p>
                        <p
                            class="mb-5"
                            data-aos="fade-up"
                            data-aos-delay="400"
                            data-aos-once="true">
                            With decades of experience working with leading animation companies, Virtue combines
                            legendary creative storytelling with the international production to build a community
                            around new worlds, engaging stories, & timeless characters.
                        </p>
                        <a
                            href="mailto:colin@virtueanimation.com"
                            data-aos="fade-up"
                            data-aos-delay="600"
                            data-aos-once="true"
                            class="btn btn-primary">
                            <span>Get in Touch</span>
                        </a>
                    </div>
                </div>
            </ng-container>
        </o-bg-image-section>
        <div
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-once="true"
            class="container-xxl pt-5 pb-5">
            <a-image imageConfig="/assets/img/logos-header.png"></a-image>
        </div>
    </div>
    <div
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-once="true"
        class="founders-pass">
        <o-bg-image-section
            bgImage="/assets/img/founders-pass.jpg"
            [overlay]="true"
            [parallaxValue]="-200"
            [parallaxSpeed]="0.25">
            <ng-container content>
                <div class="container-xxl py-7">
                    <h2
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-once="true"
                        class="text-center gradient-text fw-bold mb-5">
                        VIRTUE STUDIO FOUNDERS PASS
                    </h2>
                    <div
                        data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-once="true"
                        class="row mb-5">
                        <div class="col-lg-6 hover-translate-y">
                            <a-image
                                alt="virtue animation"
                                imageConfig="/assets/img/founder-membership.png"></a-image>
                        </div>
                        <div class="col-lg-6 hover-translate-y">
                            <a-image
                                alt="virtue animation"
                                imageConfig="/assets/img/founders-logo.png"></a-image>
                        </div>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-once="true"
                        class="text-left">
                        <div class="col-md-10 p-0">
                            <p>The Hollywood model is unsuitable.</p>
                            <p>
                                The power, product, profits – are controlled by the suits.  Our mission is to shift that
                                paradigm and pave a new path in the film industry. We believe in a model that favors
                                creators over committees, community over control, and participation over profits. We
                                want to engage with you, our founding partners, to build the next generation of great
                                stories and characters.
                            </p>
                            <p class="mb-5">
                                We welcome you to mint the Virtue key and open doors to world’s and stories beyond your
                                imagination.
                            </p>
                            <a
                                target="_blank"
                                class="btn btn-primary"
                                href="https://www.premint.xyz/virtueraffle/">
                                Sign Up for the Premint Raffle
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </o-bg-image-section>
    </div>
    <div
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-once="true"
        class="dream-team">
        <o-bg-image-section
            bgImage="/assets/img/dream-team-bg.jpg"
            [overlay]="true"
            [parallaxValue]="0"
            [parallaxSpeed]="0">
            <ng-container content>
                <div class="container-xxl pb-0">
                    <h2
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-once="true"
                        class="fw-bold mb-5">
                        DREAM TEAM
                    </h2>
                    <div
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-once="true"
                        class="text-left">
                        <div class="col-md-10 p-0">
                            <p>
                                Virtue’s leadership team brings decades of world-class creative and operational
                                experience, along with a global production network.
                            </p>
                            <p>
                                We have helped produce over 25 award-winning films from major studios such as Disney,
                                Pixar, Paramount, and Warner Brothers working alongside iconic talent from the world of
                                film, tv, music, and sports including Elton John, Kobe Bryant, Lebron James, Steph
                                Curry, Evel Knievel & more!
                            </p>
                            <p class="mb-5">
                                Virtue’s approach takes the legendary processes perfected at these iconic studios to
                                build a timeless brand alongside the best animation talent in the world.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-delay="400"
                    data-aos-once="true"
                    class="container-xxl">
                    <a-image imageConfig="/assets/img/dream-logos.png"></a-image>
                </div>
            </ng-container>
        </o-bg-image-section>
    </div>
    <o-partner-cards [partners]="partners"></o-partner-cards>
</div>
